<!-- Title -->
<h3 class="ui-portal-title">Newsletter ({{model.data.total}})
  <button class="btn-gray" (click)="onAddClick()">
    <div class="icon ui-icon-plus-gray"></div>
    <span>Add new</span>
  </button>
</h3>

<!-- Search -->
<div class="ui-portal-fixed">
  <ui-search [value]="model.search" [showButton]="false" [showIcon]="true" (onSearch)="onSearchHandler($event)"></ui-search>
</div>

<!-- Grid -->
<ui-grid
  [columns]="columns"
  [config]="config"
  [data]="model.data"
  [noWrap]="true"
  [infiniteScroll]="true"
  (onSort)="onSorting($event)"
  (onNextPageLoad)="loadPage($event)"
  (onAction)="onGridAction($event)"
>
  <!-- Template for Name column -->
  <ng-template #nameColumn let-row="row">
    <a class="ui-link text txt-limit" ui-tooltip="Edit newsletter" (click)="onEditClick(row)">{{row.name}}</a>
  </ng-template>

  <!-- Template for Subject and Body column -->
  <ng-template #textColumn let-row="row" let-col="col">
    <span class="txt-limit">{{row[col.field]}}</span>
  </ng-template>
</ui-grid>
